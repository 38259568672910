import store from '@/store';

function getUserData() {
  const userData = store.state.user.data;
  const agentId = store.getters['user/getAgentId'];
  const estateId = store.state.user.selectedEstateId;
  return {
    landownerId: userData._id,
    estateId,
    name: `${userData.firstName} ${userData.lastName}`,
    email: userData.email,
    agentId,
  };
}

export default getUserData;
